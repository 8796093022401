<template>
  <div class="min-h-screen flex flex-col">
    <TheHeader :menu-item-list="menuItemList" />
    <slot />
    <TheFooter />
  </div>
</template>

<script lang="ts" setup>
// const loading = computed(() => {
//   const appStore = $stores.app.useAppStore()
//   return appStore.mainContentLoading
// })

const menuItemList = reactive<nuxtLinkMenuItem[]>([
  {
    label: '首页',
    path: '/home',
    icon: 'home'
  },
  {
    label: '备课',
    path: '/prepareLessons',
    icon: 'tab_prepare_lessons'
  },
  {
    label: '资料库',
    path: '/database',
    icon: 'data_bank'
  }
])
</script>

<style lang="scss" scoped>
// :deep(.el-loading-mask) {
//   position: fixed;
// }
:deep(.el-loading-spinner){
  .circular {
    animation: none;
    background: url('~/assets/img/loading.gif');
    background-size: contain;
    .path {
      display: none;
    }
  }
  .el-loading-text {
    color: theme('colors.primary');
  }
}

:deep(.el-loading-mask) {
  z-index: 5000;
}
</style>
